<template>
	<div class="container">
		<div class="user-nav">
			<el-form :inline="true" class="demo-form-inline">
				<el-row type="flex" justify="space-between" align="middle">
					<el-col :span="18">
						<el-form-item prop="deptId">
						  <el-select
						    v-model="queryForm.deptId"
						    placeholder="班级"
						    style="width: 120px"
							@change="switchCls"
						  >
						    <el-option v-for="(item, idx) in constData.clsList" :key="idx" :label="item.deptName" :value="item.deptId"></el-option>
						  </el-select>
						</el-form-item>
						<el-form-item prop="status">
							<el-select
							  v-model="queryForm.status"
							  placeholder="请选择账号状态"
							  style="width: 200px"
							  clearable
							>
							  <el-option label="启用" value="0"></el-option>
							  <el-option label="禁用" value="1"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="6" style="text-align: right;">
						<el-form-item>
							<el-button type="primary" @click="getList">搜索</el-button>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</div>
		<div class="main">
			<el-row type="flex" justify="space-between">
				<el-col :span="20">
					<h3>综素班委会</h3>
				</el-col>
				<el-col :span="4" class="title-action">
					<el-button type="primary" @click="addNewUser">新增</el-button>
				</el-col>
			</el-row>
			<el-table 
			:data="mainList"
			style="margin-top: 10px;"
			border
			:header-cell-style="tableHeaderColor"
			:cell-style="cellStyleFun">
				<el-table-column type="index" label="序号" width="50"></el-table-column>
				<el-table-column prop="userName" label="用户姓名" width="100"></el-table-column>
				<el-table-column prop="classType" label="用户类别" width="80"></el-table-column>
				<el-table-column prop="_deptNm" label="班级" width="150"></el-table-column>
				<el-table-column prop="loginName" label="班委会账号"></el-table-column>
				<el-table-column prop="firstPassword" label="初始密码"></el-table-column>
				<el-table-column prop="_status" label="账号状态" width="100"></el-table-column>
				<el-table-column fixed="right" label="操作" width="250">
					<template slot-scope="scope">
						<el-button size="small" @click="userDetail(scope.row)" type="text">查看详情</el-button>
						<el-button size="small" @click="resetPwd(scope.row)" type="text">重置密码</el-button>
						<el-button size="small" @click="updateSt(scope.row, 'disable')" v-if="scope.row.status == 0" type="text">禁用</el-button>
						<el-button size="small" @click="updateSt(scope.row, 'enable')" v-if="scope.row.status == 1" type="text">启用</el-button>
						<el-button size="small" @click="delUser(scope.row)" type="text">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<el-dialog title="新增账号" :visible.sync="dialog.addUser" width="600px" center>
			<el-form :model="addUserForm" :rules="addUserRules">
				<el-form-item label="所在学校：" prop="schoolNm">
					<span>{{ addUserForm.schoolNm }}</span>
				</el-form-item>
				<el-form-item label="所在班级：" prop="deptNm">
					<span>{{ addUserForm.deptNm }}</span>
				</el-form-item>
				<el-form-item label="用户账号：" prop="loginName">
					<el-input v-model="addUserForm.loginName" style="width: 200px" placeholder="请输入用户账号" ></el-input>
				</el-form-item>
				<el-form-item label="用户姓名：" prop="userName">
					<el-input v-model="addUserForm.userName" style="width: 200px" placeholder="请输入用户姓名" ></el-input>
				</el-form-item>
				<el-form-item label="用户类别：" prop="classType">
					<el-select
					  v-model="addUserForm.classType"
					  placeholder="请选择用户类别"
					  style="width: 200px"
					>
					  <el-option label="学生" value="学生"></el-option>
					  <el-option label="家长" value="家长"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer">
				<el-button @click="closeDialog('addUser')">取消</el-button>
				<el-button type="primary" @click="confirmAddUser">确定</el-button>
			</div>
		</el-dialog>
		<el-dialog title="用户详情" :visible.sync="dialog.userDetail" width="600px" center>
			<el-form :model="curUser">
				<el-form-item label="所在学校：" prop="_schoolNm">
					<span>{{ curUser._schoolNm }}</span>
				</el-form-item>
				<el-form-item label="所在班级：" prop="_deptNm">
					<span>{{ curUser._deptNm }}</span>
				</el-form-item>
				<el-form-item label="用户账号：" prop="loginName">
					<span>{{ curUser.loginName }}</span>
				</el-form-item>
				<el-form-item label="用户姓名：" prop="userName">
					<span>{{ curUser.userName }}</span>
				</el-form-item>
				<el-form-item label="用户类别：" prop="classType">
					<span>{{ curUser.classType }}</span>
				</el-form-item>
				<el-form-item label="账号状态：" prop="studentStatus">
					<span>{{ curUser.studentStatus }}</span>
				</el-form-item>
			</el-form>
			<div slot="footer">
				<el-button type="primary" @click="closeDialog('userDetail')">确定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {
  clsAdminUserList,
  addClsAdminUser,
  updateClsAdminUser,
  delClsAdminUser,
  resetPwdClsAdminUser,
} from "@/api/teacher/manager"
import { getClass } from "@/api/public/search"
export default {
	data() {
		return {
			queryForm: {
				deptId: '',
				status: ''
			},
			constData: {
				clsList: []
			},
			mainList: [],
			dialog: {
				addUser: false,
				userDetail: false
			},
			stCn: {
				disable: '禁用',
				enable: '启用'
			},
			addUserForm: {
				loginName: '',
				userName: '',
				userId: '',
				deptId: '',
				deptNm: '',
				schoolNm: '',
				classType: '学生',
				roles: [8]
			},
			curUser: {},
			addUserRules: {
				loginName: [{ required: true, message: "请输入用户账号", trigger: "blur" }],
				userName: [{ required: true, message: "请输入用户姓名", trigger: "blur" }]
			}
		}
	},
	created(){
		this.$store.dispatch("GetClassList", this.typeId).then((res) => {
		  this.constData.clsList = res.data
		  this.queryForm.deptId = res.data[0].deptId
		  this.addUserForm.deptId = res.data[0].deptId
		  this.addUserForm.deptNm = res.data[0].deptName
		  this.addUserForm.schoolNm = res.data[0].parentName
		  this.getList()
		})
	},
	methods: {
		// 查看主列表
		getList() {
			clsAdminUserList(this.queryForm).then(res => {
				let d = res.data
				d.forEach(v => {
					v._status = ['启用', '禁用'][v.status]
					v._deptNm = v.dept.deptName
					v._schoolNm = v.dept.parentName
				})
				this.mainList = d
			})
		},
		// 切换班级
		switchCls(val) {
			let sel = this.constData.clsList.filter(v => v.deptId == val)[0]
			this.addUserForm.deptId = val
			this.addUserForm.deptNm = sel.deptName
			this.addUserForm.schoolNm = sel.parentName
		},
		// 查看详情
		userDetail(row) {
			this.curUser = row
			this.openDialog('userDetail')
		},
		// 重置密码
		resetPwd(row) {
			this.$confirm(`重置${row.userName}的密码?`, `重置密码`, {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				resetPwdClsAdminUser({
					userId: row.userId
				}).then(res => {
					this.$message.success(`${ row.userName }密码重置成功`)
				})
			})
		},
		// 启用禁用
		updateSt(row, tp) {
			let tpCn = this.stCn[tp]
			this.$confirm(`${ tpCn }用户${ row.userName }?`, `${ tpCn }用户`, {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let params = {
					userId: row.userId,
					status: tp == 'disable' ? 1 : 0
				}
				updateClsAdminUser(params).then(res => {
					this.$message.success(`${ row.userName }${ tpCn }成功`)
					this.getList()
				})
			})
		},
		// 删除用户
		delUser(row) {
			this.$confirm(`删除用户${ row.userName }?`, `删除用户`, {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				delClsAdminUser({
					userId: row.userId
				}).then(res => {
					this.$message.success(`${ row.userName }删除成功`)
					this.getList()
				})
			})
		},
		// 打开新增弹窗
		addNewUser() {
			this.addUserForm.loginName = ''
			this.addUserForm.userName = ''
			this.openDialog('addUser')
		},
		// 确认新增用户
		confirmAddUser() {
			addClsAdminUser(this.addUserForm).then(res => {
				this.$message.success('新增用户成功')
				this.closeDialog('addUser')
				this.getList()
			})
		},
		// 打开弹窗
		openDialog(nm) {
			this.dialog[nm] = true
		},
		// 关闭弹窗
		closeDialog(nm) {
			this.dialog[nm] = false
		},
		// 表头样式
		tableHeaderColor({ row, column, rowIndex, columnIndex }) {
			if (rowIndex === 0) {
				return "background-color: #F6F7FA;color: #616266;font-weight: blod;text-align:center"
			}
		},
		// 单元格样式
		cellStyleFun() {
			return "text-align:center"
		}
	}
}
</script>

<style scoped>
.user-nav {
  padding: 10px 12px;
  background: #fff;
  border: 1px solid rgba(153, 153, 153, 0.15);
  height: 63px;
  box-sizing: border-box;
}
.main {
  background-color: #fff;
  margin-top: 20px;
  padding: 10px 20px;
}
.main h3 {
  font-size: 16px;
  line-height: 32px;
  color: #616266;
  margin: 0;
}
.main .title-action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>